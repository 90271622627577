// Generated by Framer (5d65679)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";

const cycleOrder = ["BYzOJ50aj"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"BYzOJ50aj": "framer-v-q7nf6m"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("BYzOJ50aj", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "BYzOJ50aj", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "BYzOJ50aj", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-HLoL3")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-q7nf6m", className)} style={{...style}} name="Desktop"  ref={ref} {...addVariantProps("BYzOJ50aj")}/>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-HLoL3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-HLoL3 .framer-q7nf6m { position: relative; width: 1200px; height: 1400px; background-color: #ffffff; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1400
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FramervoZijtWmQ: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramervoZijtWmQ;

FramervoZijtWmQ.displayName = "Page";

FramervoZijtWmQ.defaultProps = {"width": 1200, "height": 1400};

addFonts(FramervoZijtWmQ, []);